import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import Hero from '../components/hero'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Container>
      <Hero excerpt="You just hit a route that doesn&#39;t exist... the sadness." title='Not Found' />
    </Container>
  </Layout>
)

export default NotFoundPage
